export default function (courts) {
  // console.log(courts)
  if (!courts) return []
  let results = courts.split(',')
  const ranges = results.filter(f => f.includes('-'))
  ranges.forEach(r => {
    results = results.filter(f => f !== r)
    const x = r.split('-')
    let a = +x[0]
    const b = +x[1]
    if (!isNaN(a) && !isNaN(b)) {
      for (a; a <= b; a++) {
        results.push(`${a}`.trim())
      }
    }
  })

  return results
}
